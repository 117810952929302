/* 
  We've already included normalize.css. 

  But we'd like a modern looking boilerplate. 
  Clean type, sans-serif, and a nice color palette. 
  
*/

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  touch-action: none;
  overflow: hidden;
  position: fixed;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  font-weight: 600;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

#app {
  padding: 1rem;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.1s, opacity 0.1s linear;
}